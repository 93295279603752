import {
  Box,
  Paper,
  Typography,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { LoginFooterLink } from "components/links/LoginFooterLink";
import { PasswordTextField } from "components/PasswordTextField";
import { ROUTES } from "constants/routes";
import { HttpException } from "exceptions";
import {
  Formik,
  Field,
  FieldProps,
  Form,
  FormikHelpers,
  FormikErrors,
} from "formik";
import useAuth from "hooks/auth";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import formikStyles from "styles/Formik.module.css";
import styles from "styles/Login.module.css";

export interface LoginFormState {
  email: string;
  password: string;
  submit?: string;
}

const initialValues: LoginFormState = {
  email: "",
  password: "",
};

export default function Login() {
  const { login } = useAuth();
  const router = useRouter();

  const loginHandler = async (
    email: string,
    password: string,
    setErrors: (errors: FormikErrors<LoginFormState>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const response = await login({
        email,
        password,
      });

      switch (response?.valid) {
        case "CONFIRMED":
          if (response.user?.roles[0]) {
            if (response.user?.roles[0].role_name === "norrizon_administrator")
              router.push(ROUTES.MANAGE_USERS);
            if (response.user?.roles[0].role_name === "user")
              router.push(ROUTES.SALES_ORDERS);
          }
          break;
        case "FORCE_CHANGE_PASSWORD":
          router.push(ROUTES.CHANGE_PASSWORD);
          break;
        case "NOT_CONFIRMED":
          setErrors({ submit: "Please confirm email address" });
          break;
        default:
          break;
      }
    } catch (err: unknown) {
      const error = err as HttpException;
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const formSubmitButtonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <Head>
        <title>Login</title>
        <meta name="description" content="Login" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Box>
        <div className={styles.container}>
          <div className={styles.main}>
            <Paper className={styles.box}>
              <div className={styles.logoWrapper}>
                <Image
                  src="/NSDS-logo.png"
                  alt="Norrizon Logo"
                  width={234}
                  height={90}
                />
              </div>

              <Typography variant="h2" component="h1" textAlign="center">
                Login
              </Typography>
              <Typography variant="subtitle1" component="p" textAlign="center">
                Sign in on the internal platform
              </Typography>
              <Formik
                initialValues={initialValues}
                onSubmit={async (
                  { email, password },
                  { setErrors, setSubmitting }: FormikHelpers<LoginFormState>
                ) => {
                  await loginHandler(email, password, setErrors, setSubmitting);
                }}
              >
                {({ errors, isSubmitting }) => (
                  <Form className={formikStyles.form}>
                    <Box paddingTop={4}>
                      <Field name="email">
                        {({ field }: FieldProps) => (
                          <FormControl fullWidth>
                            <TextField
                              InputProps={{ ...field }}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              id="email"
                              label="Email"
                              variant="outlined"
                              type="email"
                              placeholder="Email address"
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box paddingTop={4}>
                      <PasswordTextField
                        name="password"
                        error={Boolean(errors.submit)}
                        helperText={errors.submit ?? ""}
                        placeholder="Password"
                        id="password"
                        label="Password"
                      />
                    </Box>
                    <Box paddingTop={4} />
                    <Button
                      variant="contained"
                      fullWidth
                      component="div"
                      onClick={() => formSubmitButtonRef.current?.click()}
                      disabled={isSubmitting}
                    >
                      <button
                        ref={formSubmitButtonRef}
                        type="submit"
                        disabled={isSubmitting}
                        style={{ display: "none" }}
                      />
                      Log In
                    </Button>
                    <div className={styles.bottomLogoWrapper}>
                      <Image
                        src="/login-norrizon-logo-new.JPG"
                        alt="Norrizon Logo"
                        width={164}
                        height={82}
                      />
                    </div>
                    <Box paddingTop={4}>
                      <LoginFooterLink
                        route={ROUTES.REGISTER}
                        label="Don&lsquo;t have an account?"
                      />
                      &nbsp;&nbsp;
                      <LoginFooterLink
                        route={ROUTES.FORGOT_PASSWORD}
                        label="Forgot password"
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
            </Paper>
          </div>
        </div>
      </Box>
    </>
  );
}

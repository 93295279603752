import Link from "next/link";
import { Typography } from "@mui/material";
import { Routes } from "constants/routes";

export const LoginFooterLink = ({
  route,
  label,
}: {
  route: Routes;
  label: string;
}) => (
  <Typography
    variant="body1"
    component="span"
    sx={{ textDecoration: "underline" }}
  >
    <Link href={route}>{label}</Link>
  </Typography>
);

import Head from "next/head";
import styles from "styles/Home.module.css";

import Login from "./login";

export default function Home() {
  return (
    <div className={styles.container}>
      <Head>
        <title>Norrizon Acumatica Instance</title>
        <meta name="description" content="To be written" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Login />
    </div>
  );
}

import React from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedTextFieldProps,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Field, FieldProps } from "formik";

interface PasswordTextFieldProps
  extends Omit<OutlinedTextFieldProps, "variant"> {
  name: string;
}

export const PasswordTextField: React.FC<PasswordTextFieldProps> = ({
  name,
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <FormControl fullWidth>
          <TextField
            InputProps={{
              ...field,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            type={showPassword ? "text" : "password"}
            {...props}
          />
        </FormControl>
      )}
    </Field>
  );
};

/**
 * Global routes table
 */

export const ROUTES = {
  MANAGE_USERS: "/manage-users",
  CREATE_USER: "/manage-users/create",
  SALES_ORDERS: "/sales-orders",
  INVENTORY: "/inventory",
  SALES_REPORTS_TOTAL: "/sales-reports-total",
  SALES_REPORTS_CUSTOMER: "/sales-reports-by-customer",
  SALES_REPORTS_BRAND: "/sales-reports-by-brand",
  ACCOUNT: "/account",
  CONTACT: "/contact",
  LOGOUT: "/logout",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  CONFIRM_PASSWORD: "/confirm-password",
  CHANGE_PASSWORD: "/change-password",
  AUTOMATION_LOGGING: "/automation-logging",
} as const;

export type Routes = (typeof ROUTES)[keyof typeof ROUTES];
